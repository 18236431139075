import { InjectionToken } from '@angular/core';

export interface UserTrackingConfig {
  token: string;
  debug: boolean;
  environment: string;
}

export const USER_TRACKING_CONFIG = new InjectionToken<UserTrackingConfig>(
  'USER_TRACKING_CONFIG',
);
