import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import mixpanel, { Dict } from 'mixpanel-browser';
import { filter } from 'rxjs/operators';

import { USER_TRACKING_CONFIG } from './user-tracking-config';

@Injectable()
export class UserTrackingService {
  #userIsRegistered = false;
  #lastRouteSnapshot: ActivatedRouteSnapshot | null = null;
  #lastRoutingProperties: { trackAs: string; url: string } | null = null;

  #router = inject(Router);
  #config = inject(USER_TRACKING_CONFIG);

  constructor() {
    this.#initMixpanel(this.#config);
    this.#initRouter();
  }

  trackEvent(eventName: string, properties?: Dict) {
    mixpanel.track(eventName, properties);
  }

  trackPageChange(eventName: string, properties?: Dict) {
    mixpanel.track_pageview(properties, { event_name: eventName });
  }

  async setUser(identifier: string): Promise<void> {
    const hash = await this.#hashString(identifier);

    mixpanel.identify(hash);
    this.#userIsRegistered = true;
  }

  addUserToGroup(groupKey: string, groupId: string): boolean {
    if (this.#userIsRegistered) {
      mixpanel.add_group(groupKey, groupId);

      return true;
    }

    return false;
  }

  reset() {
    mixpanel.reset();
    this.#userIsRegistered = false;
  }

  /**
   * This method uses the browsers crypto API to create a SHA-1 hash of a given message
   * We use this to anonymous the userId for tracking
   */
  async #hashString(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const digest = await crypto.subtle.digest('SHA-1', data);
    const resultBytes = [...new Uint8Array(digest)];

    return resultBytes.map((x) => x.toString(16).padStart(2, '0')).join('');
  }

  #initMixpanel(config: any) {
    mixpanel.init(config.token, {
      debug: config.debug,
      api_host: 'https://api-eu.mixpanel.com',
      persistence: 'localStorage',
    });
  }

  #initRouter() {
    this.#router.events
      .pipe(
        takeUntilDestroyed(),
        filter(() => this.#config.environment === 'production'),
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof ActivationEnd,
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.#lastRouteSnapshot = null;
        }

        if (
          event instanceof ActivationEnd &&
          event.snapshot?.data?.['trackAs']
        ) {
          this.#lastRouteSnapshot = event.snapshot;
        }

        if (event instanceof NavigationEnd && this.#lastRouteSnapshot) {
          this.#lastRoutingProperties = {
            url: event.url,
            trackAs: this.#lastRouteSnapshot.data['trackAs'],
          };

          this.trackPageChange('pageChange', this.#lastRoutingProperties);
        }
      });
  }
}
